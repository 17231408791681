import React, {useRef} from 'react'
import Privatenrechtsschutz from './rechtsschutz/Privatenrechtsschutz'
import RechtsschutzSteps from './rechtsschutz/RechtsschutzSteps'

import { ReactComponent as LawLight } from '../assets/images/rechtsschutzImgs/LandingIlu.svg'

function RechtsschutzLanding(props) {
    const stepsRef = useRef()

    const toSteps = () => {
      var elem = stepsRef
      window.scrollTo(
        {
          top: elem.current.offsetTop - 100,
          behavior: "smooth"
        })
    }

    return (
        <>
            <>
                <div className='container-xl px-4 px-xl-0 bodyCounterPadding'>
                    <div className="row justify-content-between gy-5 gy-md-0 gx-5">
                        <div className="col-12 col-md-6 col-lg-6 my-md-auto">
                            <div className='pb-4 text-start'>
                                <span className='fw-700 fs-1 firstBlueTitle'>Jetzt für eine Rechtsschutzversicherung anfragen und das erste Jahr geschenkt erhalten!</span>
                            </div>
                            <div className='text-start'>
                            <button onClick={toSteps} className='hausratBtn py-2'>
                                Rechtsschutzversicherung finden
                            </button>
                        </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 my-md-auto">

                            <div className="">
                                <div>
                                    <LawLight className="img-fluid" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </>
            <div ref={stepsRef}>
                <RechtsschutzSteps darkMode={props.darkMode} />
            </div>

            <>
                <Privatenrechtsschutz darkMode={props.darkMode} />
            </>
            <>
                <div className='container-xl px-4 px-xl-0 bodyCounterPadding'>
                    <div className="row gx-4 justify-content-between ">
                        <div className="col-12 col-md-5 col-xl-4 text-start">
                            <div className='pb-4' >
                                <span className='fw-700 firstBlueTitle fs-1'>Vorteile der
                                    Rechtsschutzversicherung</span>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 col-xl-7 text-start">

                            <div className="pb-2">
                                <div className="row gx-3">
                                    <div className="col-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 40.599 29.09">
                                            <path id="Path_106" data-name="Path 106" d="M-697.185,788.673a2.815,2.815,0,0,1,1.724-2.792,2.751,2.751,0,0,1,3.289.555c1.295,1.22,2.532,2.5,3.791,3.758,1.623,1.62,3.291,3.2,4.847,4.882.774.838,1.221.708,1.963-.039q9.731-9.813,19.531-19.559c1.57-1.568,3.193-1.634,4.572-.232a2.8,2.8,0,0,1,.337,3.684,11.085,11.085,0,0,1-1.236,1.435q-10.685,10.7-21.382,21.394a5.131,5.131,0,0,1-2.007,1.431,2.78,2.78,0,0,1-2.941-.773c-3.905-3.868-7.781-7.765-11.67-11.65A2.626,2.626,0,0,1-697.185,788.673Z" transform="translate(697.191 -774.247)" fill="#50B8E7"></path>
                                        </svg>
                                    </div>
                                    <div className="col text-start my-auto">
                                        <span className="fw-500">Leistungsgrenze bis zu 250'000 CHF pro Fall</span>
                                    </div>
                                </div>
                            </div>
                            <div className="pb-2">
                                <div className="row gx-3">
                                    <div className="col-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 40.599 29.09">
                                            <path id="Path_106" data-name="Path 106" d="M-697.185,788.673a2.815,2.815,0,0,1,1.724-2.792,2.751,2.751,0,0,1,3.289.555c1.295,1.22,2.532,2.5,3.791,3.758,1.623,1.62,3.291,3.2,4.847,4.882.774.838,1.221.708,1.963-.039q9.731-9.813,19.531-19.559c1.57-1.568,3.193-1.634,4.572-.232a2.8,2.8,0,0,1,.337,3.684,11.085,11.085,0,0,1-1.236,1.435q-10.685,10.7-21.382,21.394a5.131,5.131,0,0,1-2.007,1.431,2.78,2.78,0,0,1-2.941-.773c-3.905-3.868-7.781-7.765-11.67-11.65A2.626,2.626,0,0,1-697.185,788.673Z" transform="translate(697.191 -774.247)" fill="#50B8E7"></path>
                                        </svg>
                                    </div>
                                    <div className="col text-start my-auto">
                                        <span className="fw-500">Monatliche Prämie für Familienangehörige</span>
                                    </div>
                                </div>
                            </div>
                            <div className="pb-2">
                                <div className="row gx-3">
                                    <div className="col-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 40.599 29.09">
                                            <path id="Path_106" data-name="Path 106" d="M-697.185,788.673a2.815,2.815,0,0,1,1.724-2.792,2.751,2.751,0,0,1,3.289.555c1.295,1.22,2.532,2.5,3.791,3.758,1.623,1.62,3.291,3.2,4.847,4.882.774.838,1.221.708,1.963-.039q9.731-9.813,19.531-19.559c1.57-1.568,3.193-1.634,4.572-.232a2.8,2.8,0,0,1,.337,3.684,11.085,11.085,0,0,1-1.236,1.435q-10.685,10.7-21.382,21.394a5.131,5.131,0,0,1-2.007,1.431,2.78,2.78,0,0,1-2.941-.773c-3.905-3.868-7.781-7.765-11.67-11.65A2.626,2.626,0,0,1-697.185,788.673Z" transform="translate(697.191 -774.247)" fill="#50B8E7"></path>
                                        </svg>
                                    </div>
                                    <div className="col text-start my-auto">
                                        <span className="fw-500">Individuelle und kostenlose telefonische Rechtsberatung</span>
                                    </div>
                                </div>
                            </div>
                            <div className="pb-2">
                                <div className="row gx-3">
                                    <div className="col-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 40.599 29.09">
                                            <path id="Path_106" data-name="Path 106" d="M-697.185,788.673a2.815,2.815,0,0,1,1.724-2.792,2.751,2.751,0,0,1,3.289.555c1.295,1.22,2.532,2.5,3.791,3.758,1.623,1.62,3.291,3.2,4.847,4.882.774.838,1.221.708,1.963-.039q9.731-9.813,19.531-19.559c1.57-1.568,3.193-1.634,4.572-.232a2.8,2.8,0,0,1,.337,3.684,11.085,11.085,0,0,1-1.236,1.435q-10.685,10.7-21.382,21.394a5.131,5.131,0,0,1-2.007,1.431,2.78,2.78,0,0,1-2.941-.773c-3.905-3.868-7.781-7.765-11.67-11.65A2.626,2.626,0,0,1-697.185,788.673Z" transform="translate(697.191 -774.247)" fill="#50B8E7"></path>
                                        </svg>
                                    </div>
                                    <div className="col text-start my-auto">
                                        <span className="fw-500">Inanspruchnahme von Assistance-Leistungen bei
                                            Unfällen</span>
                                    </div>
                                </div>
                            </div>
                            <div className="pb-2">
                                <div className="row gx-3">
                                    <div className="col-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 40.599 29.09">
                                            <path id="Path_106" data-name="Path 106" d="M-697.185,788.673a2.815,2.815,0,0,1,1.724-2.792,2.751,2.751,0,0,1,3.289.555c1.295,1.22,2.532,2.5,3.791,3.758,1.623,1.62,3.291,3.2,4.847,4.882.774.838,1.221.708,1.963-.039q9.731-9.813,19.531-19.559c1.57-1.568,3.193-1.634,4.572-.232a2.8,2.8,0,0,1,.337,3.684,11.085,11.085,0,0,1-1.236,1.435q-10.685,10.7-21.382,21.394a5.131,5.131,0,0,1-2.007,1.431,2.78,2.78,0,0,1-2.941-.773c-3.905-3.868-7.781-7.765-11.67-11.65A2.626,2.626,0,0,1-697.185,788.673Z" transform="translate(697.191 -774.247)" fill="#50B8E7"></path>
                                        </svg>
                                    </div>
                                    <div className="col text-start my-auto">
                                        <span className="fw-500">Die kombinierte Deckung bietet die Vorteile und
                                            Leistungen der beiden Produkte</span>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <div className="row gx-3">
                                    <div className="col-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" viewBox="0 0 40.599 29.09">
                                            <path id="Path_106" data-name="Path 106" d="M-697.185,788.673a2.815,2.815,0,0,1,1.724-2.792,2.751,2.751,0,0,1,3.289.555c1.295,1.22,2.532,2.5,3.791,3.758,1.623,1.62,3.291,3.2,4.847,4.882.774.838,1.221.708,1.963-.039q9.731-9.813,19.531-19.559c1.57-1.568,3.193-1.634,4.572-.232a2.8,2.8,0,0,1,.337,3.684,11.085,11.085,0,0,1-1.236,1.435q-10.685,10.7-21.382,21.394a5.131,5.131,0,0,1-2.007,1.431,2.78,2.78,0,0,1-2.941-.773c-3.905-3.868-7.781-7.765-11.67-11.65A2.626,2.626,0,0,1-697.185,788.673Z" transform="translate(697.191 -774.247)" fill="#50B8E7"></path>
                                        </svg>
                                    </div>
                                    <div className="col text-start my-auto">
                                        <span className="fw-500">Deckung bis zu 10'000 CHF bei Streitigkeiten mit der
                                            Nachbarschaft</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            <>
                <div className='container-xl px-4 px-xl-0 bodyCounterPadding'>
                    <div className="row gx-4 justify-content-between ">
                        <div className="col-12 col-md-5 col-xl-4 text-start">
                            <div className='pb-4' >
                                <span className='fw-700 firstBlueTitle fs-1'>Monatliche Prämie</span>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 col-xl-7 text-start">

                            <div className='pb-3'>
                                <span className='fw-500 fs-5 normalTextToWhite'>
                                    Die monatliche Prämie bietet Ihnen maximale Sicherheit für alle gemeinsamen Streitigkeiten,
                                    die Ihnen oder Ihren Familienmitgliedern entstehen.
                                    Kinder bis zu 18 Jahren, die mit den versicherten Erwachsenen in einem gemeinsamen Haushalt leben,
                                    sind kostenlos mitversichert.
                                </span>
                            </div>


                        </div>
                    </div>
                </div>
            </>
            <>
                <div className='container-xl px-4 px-xl-0 bodyCounterPadding'>
                    <div className="row gx-4 justify-content-between ">
                        <div className="col-12 col-md-5 col-xl-4 text-start">
                            <div className='pb-4' >
                                <span className='fw-700 firstBlueTitle fs-1'>Was sind die Vorteile?</span>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-7 col-xl-7 text-start">

                            <div className='pb-3'>
                                <span className='fw-500 fs-5 normalTextToWhite'>
                                    Deckt die Kosten von Rechtsstreitigkeiten im
                                    Zusammenhang mit Mobilität und Strassenverkehr
                                </span>
                            </div>


                        </div>
                    </div>
                </div>
            </>
        </>
    )
}

export default RechtsschutzLanding