import "./App.css";
import './assets/css/analys360.css'
import './assets/css/mainComponents.css'
import './assets/css/hausrat.css'
import './assets/css/krankenkasse.css'
import './assets/css/mainComponents.css'
import './assets/css/pension.css'
import './assets/css/plus.css'
import './assets/css/uberUns.css'
import './assets/css/versicherungen.css'

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import React, { lazy, Suspense } from 'react'

import MoonLoader from "react-spinners/ClipLoader";
import RechtsschutzLanding from "./components/RechtsschutzLanding";

const FinanuAppQuestions = lazy(() => import('./components/support/FinanuAppQuestions.js'))
const KrankQuestions = lazy(() => import('./components/support/KrankQuestions.js'))
const RechQuestions = lazy(() => import('./components/support/RechQuestions.js'))
const AutoQuestions = lazy(() => import('./components/support/AutoQuestions.js'))
const PensionQuestions = lazy(() => import('./components/support/PensionQuestions.js'))
const HypothekenQuestions = lazy(() => import('./components/support/HypothekenQuestions.js'))
const FinanzenQuestions = lazy(() => import('./components/support/FinanzenQuestions.js'))

const Header = lazy(() => import('./Header'))
const Footer = lazy(() => import('./Footer'))

const Blog1 = lazy(() => import('./components/homepage/blogs/Blog1.js'))
const Blog2 = lazy(() => import('./components/homepage/blogs/Blog2.js'))
const Blog3 = lazy(() => import('./components/homepage/blogs/Blog3.js'))
const Blog4 = lazy(() => import('./components/homepage/blogs/Blog4.js'))
const Blog5 = lazy(() => import('./components/homepage/blogs/Blog5.js'))
const Blog6 = lazy(() => import('./components/homepage/blogs/Blog6.js'))
const Blog7 = lazy(() => import('./components/homepage/blogs/Blog7.js'))

const MainComponents = lazy(() => import('./components/MainComponents'))
const Krankenkasse = lazy(() => import('./components/Krankenkasse'))
const PensionPlan = lazy(() => import('./components/PensionPlan'))
const Hausrat = lazy(() => import('./components/Hausrat'))
const Analys360 = lazy(() => import('./components/Analys360'))
const Rechtsschutz = lazy(() => import('./components/Rechtsschutz'))
const UberUns = lazy(() => import('./components/UberUns'))
const ToggleDarkMode = lazy(() => import('./components/ToggleDarkMode'))
const ScrollTop = lazy(() => import('./ScrollTop.js'))
const Auto = lazy(() => import('./components/Auto'))
const Login = lazy(() => import('./components/login/Login'))
const Versicherungen = lazy(() => import('./components/Versicherungen'))
const Sympany = lazy(() => import('./components/versicherungen/Sympany'))
const Swica = lazy(() => import('./components/versicherungen/Swica'))
const GroupeMutuel = lazy(() => import('./components/versicherungen/GroupeMutuel'))
const Allianz = lazy(() => import('./components/versicherungen/Allianz'))
const Plus = lazy(() => import('./components/Plus'))
const Privacy = lazy(() => import('./components/Privacy'))
const Impressum = lazy(() => import('./components/Impressum'))
const TermsAndConditions = lazy(() => import('./components/TermsAndConditions'))
const ContactUs = lazy(() => import('./components/ContactUs.js'))

function App() {
  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("darkModeKey")) !== true &&
      JSON.parse(localStorage.getItem("darkModeKey")) !== false
    ) {
      localStorage.setItem("darkModeKey", "false");
    }
    setDarkMode(localStorage.getItem("darkModeKey"));
  }, []);

  const [darkMode, setDarkMode] = useState(
    JSON.parse(localStorage.getItem("darkModeKey"))
  );
  useEffect(() => {
    if (darkMode === true) {
      document.body.classList.add("darkTheme");
    } else {
      document.body.classList.remove("darkTheme");
    }
    localStorage.setItem("darkModeKey", JSON.stringify(darkMode));
    setDarkMode(JSON.parse(localStorage.getItem("darkModeKey")));
  }, [darkMode]);

  return (
    <div className="App">
      <>
        <BrowserRouter>
          <ScrollTop>
            <Suspense fallback={<div className="loaderStyle"><MoonLoader size={100} color="#50b8e7" /></div>}>

              <Header darkMode={darkMode} />
              <ToggleDarkMode darkMode={darkMode} setDarkMode={setDarkMode} />
              <Routes>
                {/* Home */}
                <Route exact path="/" element={<MainComponents darkMode={darkMode} />} />
                <Route exact path="/blog/erklarungundunterschiedealternativ" element={<Blog1 darkMode={darkMode} />} />
                <Route exact path="/blog/dievorsorgestruktur" element={<Blog2 darkMode={darkMode} />} />
                <Route exact path="/blog/10unglaublichefakten" element={<Blog3 darkMode={darkMode} />} />
                <Route exact path="/blog/autoversicherung" element={<Blog4 darkMode={darkMode} />} />
                <Route exact path="/blog/hypothek" element={<Blog5 darkMode={darkMode} />} />
                <Route exact path="/blog/autoversicherungsvergleich" element={<Blog6 darkMode={darkMode} />} />
                <Route exact path="/blog/5schritte" element={<Blog7 darkMode={darkMode} />} />

                {/* rechtsschutzLanding */}
                <Route exact path="/rechtsschutz-aktion" element={<RechtsschutzLanding />} />


                {/* versicherungen */}
                <Route exact path="/versicherungen" element={<Versicherungen />} />
                <Route exact path="/versicherungen/sympany" element={<Sympany />} />
                <Route exact path="/versicherungen/swica" element={<Swica />} />
                <Route exact path="/versicherungen/groupe-mutuel" element={<GroupeMutuel darkMode={darkMode} />} />
                <Route exact path="/versicherungen/allianz" element={<Allianz />} />
                <Route exact path="/versicherungen/krankenkasse" element={<Krankenkasse darkMode={darkMode} />} />
                <Route exact path="/versicherungen/rechtsschutz" element={<Rechtsschutz darkMode={darkMode} />} />
                <Route exact path="/versicherungen/auto-und-motorrad" element={<Auto darkMode={darkMode} />} />

                {/* Finanzen */}
                <Route exact path="/finanzen/pensionsplanung-und-ruhestand" element={<PensionPlan darkMode={darkMode} />} />
                <Route exact path="/finanzen/haus-und-hypothek" element={<Hausrat darkMode={darkMode} />} />
                <Route exact path="/finanzen/360-Budgetanalyse" element={<Analys360 />} />
                <Route exact path="/uber-uns" element={<UberUns darkMode={darkMode} />} />
                <Route exact path="/login" element={<Login />} darkMode={darkMode} />

                {/* Plus */}
                <Route exact path="/finanu-plus" element={<Plus darkMode={darkMode} />} />
                <Route exact path="/datenschutz" element={<Privacy />} />
                <Route exact path="/impressum" element={<Impressum />} />
                <Route exact path="/allgemeine-geschaftsbedingungen" element={<TermsAndConditions />} />

                {/* Support Routes */}
                <Route exact path="/support" element={<ContactUs darkMode={darkMode} />} />
                <Route exact path="/support/finanu-app" element={<FinanuAppQuestions darkMode={darkMode} />} />
                <Route exact path="/support/krankenkasse" element={<KrankQuestions darkMode={darkMode} />} />
                <Route exact path="/support/rechtsschutz" element={<RechQuestions darkMode={darkMode} />} />
                <Route exact path="/support/auto-und-motorrad" element={<AutoQuestions darkMode={darkMode} />} />
                <Route exact path="/support/vorsorge-und-pensionierung" element={<PensionQuestions darkMode={darkMode} />} />
                <Route exact path="/support/haus-und-hypothek" element={<HypothekenQuestions darkMode={darkMode} />} />
                <Route exact path="/support/finanzen-und-budget" element={<FinanzenQuestions darkMode={darkMode} />} />

              </Routes>
              <Footer />
            </Suspense>
          </ScrollTop>
        </BrowserRouter>
      </>
    </div>
  );
}

export default App;
